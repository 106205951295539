<template>
  <div :id="video_container" style="height: 300px;"></div>
</template>

<script>
import EZUIKit from 'ezuikit-js';
//https://github.com/Ezviz-OpenBiz/EZUIKit-JavaScript-npm
export default {
  props: {
    //设备序列号
    SerialNumber: {
      type: String,
      default: "",
    },
    //萤石云开放平台API返回的AccessToken
    AccessToken: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 400,
    },
    //通道，默认：1
    channel: {
      type: Number,
      default: 1,
    },
    //是否打开声音
    audio: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      video_container: '',
      model: 'live', //模式：直播：live，回放：rec，云储存回放：cloud.rec 默认：live
      //_channel: this.channel === undefined ? 1 : this.channel,
      player: undefined,
      _audio: this.audio ? 1 : 0,
    };
  },
  created() {
    // console.log("监控PC简易版：" + this.SerialNumber);
    this.video_container = "video_" + this.SerialNumber;
  },
  mounted() {
    this.initVideoContainer();
  },
  methods: {
    onHandleSuccess(data) {
      // console.log("播放成功回调：");
    },
    onHandleError(data) {
      // console.log(`${this.SerialNumber},播放发生错误回调：`);
      // console.log(data);
    },
    initVideoContainer() {
      let url = "ezopen://open.ys7.com/{SerialNumber}/{channel}.{model}";
      url = url.replace("{model}", this.model)
          .replace("{SerialNumber}", this.SerialNumber)
          .replace("{channel}", this.channel);
      this.player = new EZUIKit.EZUIKitPlayer({
        id: this.video_container, // 视频容器ID
        accessToken: this.AccessToken,//授权过程获取的access_token
        url: url,//视频ezopen协议播放地址
        width: this.width,
        height: this.height,
        audio: this._audio,//是否默认开启声音 1：打开（默认） 0：关闭
        // template: 'simple',//播放器模板，可以通过选定模板，使用内置的播放器样式，组件 simple：极简版;standard：标准版;security：安防版;vioce：语音版
        themeData: {
          "autoFocus": 5,
          "poster": "https://resource.eziot.com/group1/M00/00/89/CtwQEmLl8r-AZU7wAAETKlvgerU237.png",
          "header": {
            "color": "#FFFFFF",
            "activeColor": "#1890FF",
            "backgroundColor": "#00000021",
            "btnList": [
              {
                "iconId": "deviceID",
                "part": "left",
                "defaultActive": 0,
                "memo": "顶部设备名称",
                "isrender": 1
              },
              {
                "iconId": "deviceName",
                "part": "left",
                "defaultActive": 0,
                "memo": "顶部设备ID",
                "isrender": 1
              },
            ]
          },
          "footer": {
            "color": "#FFFFFF",
            "activeColor": "#1890FF",
            "backgroundColor": "#00000021",
            "btnList": []
          }
        },
        handleSuccess: this.onHandleSuccess(),
        handleError: (err) => {
          console.log(`${this.SerialNumber},播放发生错误回调：${err}`);
        },
        seekFrequency: 5000,//为避免频繁拖动播放异常，可设置模板回放时间轴拖动防抖间隔，默认值为2000（2秒），可取2000（2秒），3000（3秒），4000（4秒），5000（5秒）
      });
    }
  },
};
</script>
<style src="../assets/css/Base.css"></style>
<style lang="less" scoped>

</style>