import axios from 'axios';

/**
 * 请求方式枚举
 * @type {{POST: string, GET: string}}
 */
export const RequestMethod = {
    POST: 'post', GET: 'get'
};

/**
 * 内容类型枚举
 * @type {{MULTIPART_FORM_DATA: string, FORM_URLENCODED: string, JSON: string}}
 */
export const ContentType = {
    FORM_URLENCODED: 'application/x-www-form-urlencoded',
    MULTIPART_FORM_DATA: 'multipart/form-data',
    JSON: 'application/json'
};

/**
 * 封装axios的功能，进行HTTP请求（最基础使用，后面可扩展）
 */
export default {
    /**
     * 发送HTTP请求的通用函数。
     * @param {string} url - 请求链接。
     * @param {object} params - 请求的参数。
     * @param {string} [method=RequestMethod.POST] - 请求的方法类型（RequestMethod.POST 或 RequestMethod.GET）。
     * @param {string} contentType - 请求的内容类型。
     * @returns {Promise} - 返回处理结果的Promise对象。
     */
    async request(url, params, method = RequestMethod.POST, contentType = ContentType.FORM_URLENCODED) {
        if (!url) {
            console.error("请求必须包含URL");
            return {code: 2, msg: "缺少URL参数"};
        }
        const headers = {'Content-Type': contentType};
        try {
            let response = undefined;
            switch (method) {
                case RequestMethod.POST:
                    response = await axios.post(url, params, {headers});
                    break;
                case RequestMethod.GET:
                    response = await axios.get(url, {params, headers});
                    break;
                default: // 默认使用POST进行请求
                    response = await axios.post(url, params, {headers});
            }
            return response.status === 200 ? response.data : {code: -1, msg: "请求发生错误，请稍后再试"};
        } catch (e) {
            console.error(`${url} ===> 发生错误: ${e.message}`);
        }
        return {code: -2, msg: `操作发生错误`};
    },

    /**
     * 发送HTTP GET 请求。
     * @param {string} url - 请求链接。
     * @param {object} params - 请求的参数。
     * @param {string} contentType - 请求的内容类型。
     * @returns {Promise} - 返回处理结果的Promise对象。
     */
    async GET(url, params, contentType = ContentType.FORM_URLENCODED) {
        return this.request(url, params, RequestMethod.GET, contentType);
    },

    /**
     * 发送HTTP GET 请求。
     * @param {string} url - 请求链接。
     * @param {object} params - 请求的参数。
     * @param {string} contentType - 请求的内容类型。
     * @returns {Promise} - 返回处理结果的Promise对象。
     */
    async POST(url, params, contentType = ContentType.FORM_URLENCODED) {
        return this.request(url, params, RequestMethod.POST, contentType);
    },

    /**
     * 上传文件到指定路径的函数。
     * @param {string} url - 请求链接。
     * @param {FormData} formData - 要上传的表单数据。
     * @param {object} config - 额外的axios配置。
     * @returns {Promise} - Promise对象，表示是否成功上传文件。
     */
    async uploadFile(url, formData, config = {}) {
        return this.request(url, formData, {'Content-Type': ContentType.MULTIPART_FORM_DATA, ...config}, RequestMethod.POST);
    },
}


