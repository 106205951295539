<template>
  <div :style="{ width: width + 'px', height: height + 'px' }">
    <div v-if="title" class="webrtc-title">{{ title }}</div>
    <video ref="videoPlayer"
           controls
           disablePictureInPicture="true"
           controlslist="nodownload nofullscreen noremoteplayback"
           autoplay
           style="width: 100%; height: 93%"/>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    // 信令请求处理函数，必需
    signalRequest: {
      type: Function,
      required: true,
      default: undefined,
    },
    // 视频宽度，默认为 640
    width: {
      type: [Number, String],
      default: 640,
    },
    // 视频高度，默认为 380
    height: {
      type: [Number, String],
      default: 380,
    },
    // 视频样式，默认为黑色背景
    style: {
      type: Object,
      default: function () {
        return {backgroundColor: 'black'};
      },
    },
    // 透传参数给信令服务器的数据对象，默认为空对象
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      // WebRTC连接对象
      connect: undefined,
      // 从信令服务器请求回来的SDP值
      SLResponseSdp: undefined,
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  beforeDestroy() {
    // 销毁前停止播放
    this.stop();
  },
  methods: {
    /**
     * 初始化WebRTC需要的东西
     * 大概流程：实例RTCPeerConnection--设置好事件--添加视频轨道和音频轨道--创建offer--设置本地描述--开始信令请求--设置answer
     */
    init() {
      this.connect = new RTCPeerConnection(null);
      // 监听轨道事件
      this.connect.ontrack = (event) => {
        const stream = event.streams[0];
        const videoElement = this.$refs.videoPlayer;
        // 根据浏览器支持设置视频源
        if ('srcObject' in videoElement) {
          videoElement.srcObject = stream;
        } else {
          videoElement.src = window.URL.createObjectURL(stream);
        }
      };
      // 添加音频和视频轨道
      this.connect.addTransceiver("audio", {direction: "recvonly"});
      this.connect.addTransceiver("video", {direction: "recvonly"});
      // 初始化信令请求
      this.initSignalRequest();
    },

    /**
     * 开始请求信令服务器处理
     */
    async initSignalRequest() {
      try {
        // 检查信令请求函数是否存在
        if (!this.signalRequest) {
          console.error(`${this.$options.name}.无法完成初始化，请设置好信令服务器请求处理`);
          return;
        }
        // 创建offer
        const offer = await this.connect.createOffer();
        await this.connect.setLocalDescription(offer);

        // 发起信令请求并获取SDP值
        this.SLResponseSdp = await this.signalRequest(offer, this.data);
        // 设置远端描述
        if (this.SLResponseSdp) {
          await this.connect.setRemoteDescription(new RTCSessionDescription({
            type: 'answer',
            sdp: this.SLResponseSdp
          }));
        }
      } catch (e) {
        console.error('请求信令服务器发生错误:', e);
      }
    },
    // 停止播放
    stop() {
      if (this.connect) {
        this.connect.close();
      }
    },
  },
};


/**
 * 调用示例：
 *
 * <template>
 *     <WebRTCPlayView
 *         :signalRequest="signalRequest"
 *         style="background-color: #2c3e50;"
 *         :data="{
 *           steamId:'00046204043140000051'
 *           ,username:'admin'
 *           ,password:'12345678'
 *           ,channelid:'1'
 *           ,nettype:'ipv4'
 *         }"
 *         :width="640"
 *         :height="380"/>
 * </template>
 *
 * <script setup>
 * import WebRTCPlayView from "@/components/WebRTCPlay/WebRTCVideoV3.vue";
 * import HttpUtil from "@/utils/HttpUtil";
 *
 * const signalRequest = async (offer, data) => {
 *   const domain = "wrtc.dvr168.org"
 *   // 向信令服务器发送请求
 *   const apiUrl = `https://${domain}:1990/rtc/v1/play/`;
 *   const params = {
 *     "api": apiUrl,
 *     "channelid": data.channelid,
 *     "clientip": null,
 *     "nettype": data.nettype,
 *     "sdp": offer.sdp,
 *     "stream": data.steamId,
 *     "streamurl": `webrtc://${domain}/play/${data.steamId}`,
 *     "tid": Number(parseInt(new Date().getTime() * Math.random() * 100)).toString(16).slice(0, 7),
 *     "username": data.username,
 *     "password": data.password,
 *   };
 *   const response = await HttpUtil.request(apiUrl, params, "application/json");
 *   if (response && response.code === 0) {
 *     return response.sdp;
 *   } else {
 *     console.error('无法从信令服务器获取 SDP');
 *   }
 *   return undefined;
 * }
 * </ script>
 *
 *
 */


</script>

<style scoped>
.webrtc-title {
  height: 48px;
  line-height: 48px;
  display: flex;
  justify-content: space-between;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.13);
  color: rgb(255, 255, 255);
  padding: 0 10px;
}
</style>
